/*image/map settings*/

.leaflet-container{
  width:100vw;
  height:94vh;
}

.image-preview{
  width:400px;
  height: auto;
}

.panoimage{
  width:100%;
  height:auto;
}

.panoimageOriginal{
  width:100%;
  height:auto;
}

/*Header settings*/

strong {
  font-size:1.5vmin;
  font-weight:900;
}

.header{
  display: flex;
  justify-content: space-between;
  height:6vh;
  background-color:#333;
  overflow:hidden;
  
}

.header-left{
  font-size: 30px;
}

.title{
  font-size: 4vmin;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: 700;
}

.header div{
  display:flex;
  float: left;
  align-items: center;
  padding:1vmin;

  color: #f2f2f2;
  text-align: center;
  text-decoration: none;
  
  
}

.icon{
  height:5vh;
  padding:0px 0px;
  vertical-align: middle;
}

.iconimg{
  height:4vh;
  width:4vh;
  padding:0px;
  
}

.header-right div{
  border-style:none none none solid;
  border-color: #f2f2f2;
}
 
.infoLinks div{
  border-style: none;
}


.infoLinks a{
  font-size: 1.8vmin;
  text-decoration: none;
  color:#f2f2f2;
}

.infoLinks:hover{
  background-color: rgb(82, 82, 82);
}

.infoLinks div:hover{
  background-color: rgb(82, 82, 82);
}

.infoLinks p{
  
  margin:0.5vmin;
  font-size:1.2vmin;
}

.createdby{
 
  display:flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 3vw;
  height:100%;
  
}

.createdby p{
  
  margin:2px;
  font-size:1.2vmin;
}

.socialicons{
  border-style: none !important;
}

.fa {
  height:2vh;
  width:2vh;
  padding:1vh;
  
  text-align: center;
  text-decoration: none;
  
}

.fa:hover {
  opacity: 0.7;
}


.fa-linkedin {
  background: #3B5998;
  color: white;
}

.fa-github {
  background: #000000;
  color: white;
}


/*Side Menu Settings*/


.controls{
  text-align:center;
}

.controls p{
  font-size: 1vmax;
}

.transform-component-module_content__2jYgh{
  cursor:move;
 
}

.hiddenbutton{
  visibility: hidden;
  position: absolute;
}

.hiddenbuttonenter{
  visibility: hidden;
  position:absolute;
}

.hiddenbuttonexit{
  visibility: hidden;
  position:absolute;
}


/*fullscreen settings*/
.fullscreen-enabled .panoimage{
  height:50vh;
  width:auto;
  margin-left:auto;
  margin-right:auto;
}

.fullscreen-enabled .panoimageOriginal{
  width:100%;
  height:auto;
}

.fullscreen-enabled .transform-component-module_wrapper__1_Fgj {
  margin-left:auto !important;
  margin-right:auto !important;
  margin-top:auto !important;
}

.fullscreen-enabled .carouselelement-single {
  margin-top: 12vh;
}

.fullscreen-enabled .panzoom-single{
  width:100vw;
  height:100vh;
}

.enterfullscreen{

  background-color: #272727;
  border: none;
  color: white;
  padding: 1vh;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 2vh;
  cursor:pointer;
}

.enterfullscreen:hover{
  opacity:0.8;
}

.exitfullscreen{
  visibility: hidden;
  position:absolute;
}

.exitfullscreen:hover{
  opacity: 0.7;
  cursor:pointer;
}

.fullscreen-enabled .exitfullscreen{
  position:absolute;
  visibility: visible;
  top:0px;
  right:0px;
  font-size:2vmax;
  background-color:#00000000;
  margin:1vw;
  border:none;
  color:white;
  text-decoration:none;
}